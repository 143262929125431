<template>
  <div class="market-trends__list">
    <el-button
      class="back-button back-button--insight"
      size="small"
      @click="hasHistory()
        ? $router.go(-1)
        : $router.push('/')"
      type="primary">
      {{ $t('global.back') }}
    </el-button>
    <el-form
      ref="form"
      class="filters"
      @submit.native="setFilters">
      <h1 class="title title--blue">{{ $t('marketTrends.archive') }}</h1>
      <el-row
        class="filters__wrapper"
        type="flex"
        align="middle">
        <el-form-item
          :label="$t('marketTrends.type')"
          class="filters__orderby">
          <el-select
            v-model="selectedType"
            :placeholder="$t('filters.orderby')"
            @change="setType">
            <el-option
              v-for="item in marketTrendsFilterItems"
              :key="item.type"
              :label="$t(`marketTrends.${item.type}`)"
              :value="item.type">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('filters.orderby')"
          class="filters__orderby">
          <el-select
            v-model="selectedOrder"
            :placeholder="$t('filters.orderby')"
            @change="setFilters">
            <el-option
              v-for="item in orderbyItems"
              :key="item.value"
              :label="$t(`filters.${item.label}`)"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="filters__search">
          <el-input
            class="filters__search-input"
            :placeholder="$t('filters.search')"
            v-model="searchTerm"
            @keyup.enter.native="setFilters"
            @clear="setFilters"
            clearable>
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
          </el-input>
        </el-form-item>
      </el-row>
    </el-form>
    <div
      class="items"
      v-loading="isLoading">
      <div class="items__inner">
        <div v-if="isEmptyResponse">{{ $t('global.noResults') }}</div>
        <CommunicationRow :items="items" :entity="entity"></CommunicationRow>
      </div>
    </div>
    <el-pagination
      background
      @current-change="handleCurrentPageChange"
      :hide-on-single-page="true"
      :current-page.sync="currentPage"
      :page-size="perPage"
      :total="itemsTotal"
      layout="prev, pager, next">
    </el-pagination>
  </div>
</template>

<script>
import orderbyItems from '@/config/orderbyItems';
import marketTrendsItems from '@/config/marketTrendsItems';
import { investmentAdvisoryApi } from '@/api';
import { mapGetters } from 'vuex';
import CommunicationRow from '@/components/CommunicationRow.vue';

export default {
  name: 'MarketTrendsList',
  props: ['type'],
  data() {
    return {
      items: [],
      itemsTotal: 0,
      pagesTotal: 0,
      perPage: 6, // Default page post limit
      currentPage: 1,
      isLoading: false,
      isEmptyResponse: false,
      selectedOrder: 'date-desc',
      orderbyItems,
      selectedType: this.type || 'daily',
      marketTrendsItems,
      searchTerm: '',
      order: '',
      orderby: '',
      metaQuery: true,
      metaKey: 'typology',
      metaValue: this.type || 'daily',
      entity: 'MarketTrend',
    };
  },
  components: {
    CommunicationRow,
  },
  created() {
    this.parseQueryFilters(this.$route.query, this.$route.params.type);
    this.getRemoteItems(this.$route.params.type);
  },
  beforeRouteUpdate(to, from, next) {
    this.parseQueryFilters(to.query, to.params.type);
    this.getRemoteItems(to.params.type);
    next();
  },
  computed: {
    ...mapGetters('users', ['currentUser']),
    marketTrendsFilterItems() {
      return marketTrendsItems.filter((item) => {
        if (!this[item.type]) {
          this[item.type] = true;
          return true;
        }
        return false;
      });
    },
  },
  methods: {
    async getRemoteItems(type) {
      try {
        this.isLoading = true;
        this.metaValue = type;
        const params = {
          per_page: this.perPage,
          page: this.currentPage,
          order: this.order,
          orderby: this.orderby,
          search: this.searchTerm,
          meta_key: this.metaKey,
          meta_value: this.metaValue,
          meta_query: this.metaQuery,
          group: this.currentUser.user_group.id,
        };
        const response = await investmentAdvisoryApi.getMarketTrends(params);
        this.isLoading = false;
        this.items = response.data;
        this.itemsTotal = parseInt(response.headers['x-wp-total'], 10);
        this.pagesTotal = parseInt(response.headers['x-wp-totalpages'], 10);
        this.isEmptyResponse = (this.items.length === 0);
      } catch (error) {
        this.isLoading = false;
        if (error && error.response) {
          this.$notify({
            type: 'error',
            dangerouslyUseHTMLString: true,
            message: error.response.data.message,
          });
        }
        throw error;
      }
    },
    handleCurrentPageChange(page) {
      this.$router.push({
        name: 'MarketTrendsList',
        query: {
          ...this.$route.query,
          page,
        },
      });
    },
    parseQueryFilters(query, type) {
      this.searchTerm = query.search || '';
      this.currentPage = query.page ? parseInt(query.page, 10) : 1;
      this.selectedOrder = query.order || 'date-desc';
      this.selectedType = type || 'daily';

      const orderValue = this.orderbyItems.find((item) => item.value === this.selectedOrder);
      this.order = orderValue.params.order;
      this.orderby = orderValue.params.orderby;
    },
    setFilters() {
      this.$router.push({
        name: 'MarketTrendsList',
        query: {
          search: this.searchTerm,
          order: this.selectedOrder,
          page: 1,
        },
      }).catch(() => {}); // Fixes a bug in vue router same page navigation
    },
    setType() {
      this.$router.push({
        name: 'MarketTrendsList',
        params: {
          type: this.selectedType,
        },
      }).catch(() => {}); // Fixes a bug in vue router same page navigation
    },
    hasHistory() {
      return window.history.length > 2;
    },
  },
};
</script>
